'use client';
import {
  mixpanelPageView,
  mixpanelPurchaseEvent,
  mixpanelSetIdentity,
} from '@/lib/mixpanel/services/client';

import { createHubspotPurchaseEvent } from '@/lib/hubspot/services/dealService';
import { useEffect, useRef } from 'react';
import { usePathname } from 'next/navigation';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  hubspotDealIdAtom,
  shopifyDraftOrderIdAtom,
  shopifyCartIdWithStorageAtom,
  mixpanelUserIdentityAtom,
} from '@/store/cartStore';
import useCart from '@/hooks/useCart';
import { getHomePageUrl } from '@/lib/pathUtils';
import { useFeatureGate, useStatsigClient } from '@statsig/react-bindings';
import { isSoleProprietorAtom } from '@/store/cartStore';

export default function Template({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();

  const searchParams = new URLSearchParams(
    typeof window !== 'undefined' ? window.location.search : ''
  );
  const { fetchShopifyDraftOrder, setShopifyOrderAsPaid } = useCart();
  const mixpanelUserIdentity = useAtomValue(mixpanelUserIdentityAtom);
  const [hubspotDealId, setHubspotDealId] = useAtom(hubspotDealIdAtom);
  const [shopifyOrderId, setShopifyOrderId] = useAtom(shopifyDraftOrderIdAtom);
  const setCartIdWithStorageAtom = useSetAtom(shopifyCartIdWithStorageAtom);
  const isSoleProprietorValue = useAtomValue(isSoleProprietorAtom);
  const gate = useFeatureGate('itn');

  const { logEvent } = useStatsigClient();

  const prevPathnameRef = useRef('');

  useEffect(() => {
    if (prevPathnameRef.current !== pathname) {
      mixpanelPageView();
      prevPathnameRef.current = pathname;

      if (mixpanelUserIdentity !== null) {
        mixpanelSetIdentity(mixpanelUserIdentity);
      }
    }
  }, [pathname]);

  // Use for SSU
  const formattedAuthUrl = () => {
    const authDomain = 'https://auth.ikhokha.com/signup';
    const appCode = 'ik-website';
    const channel = 'Web';
    const callbackUrl = 'https://ikhokha.com/auth/callback';
    const siteUrl = getHomePageUrl();
    const redirectUrl = 'https://ikhokha.com/ssu-confirmation';

    if (redirectUrl && callbackUrl)
      return `${authDomain}?appCode=${appCode}&channel=${channel}&redirectUri=${encodeURIComponent(redirectUrl)}&callbackUrl=${encodeURIComponent(callbackUrl)}`;
    else
      return `${authDomain}?appCode=${appCode}&channel=${channel}&redirectUri=${encodeURIComponent(`${siteUrl}/signup-thank-you`)}&callbackUrl=${encodeURIComponent(`${siteUrl}/callback`)}`;
  };

  useEffect(() => {
    if (
      ['/completed-order', '/completed-card-order'].includes(pathname) &&
      gate.value === false
    ) {
      const completedOrder = async () => {
        if (shopifyOrderId && hubspotDealId) {
          const orderResponse = await fetchShopifyDraftOrder(shopifyOrderId);
          if (orderResponse) {
            const { email, lineItems, totalPrice } = orderResponse;
            setShopifyOrderAsPaid(shopifyOrderId);
            mixpanelSetIdentity(email ?? mixpanelUserIdentity);
            mixpanelPurchaseEvent(
              shopifyOrderId,
              hubspotDealId,
              lineItems,
              totalPrice,
              isSoleProprietorValue
            );
            logEvent('purchase', shopifyOrderId, {
              transaction_id: shopifyOrderId,
              hubspot_deal_id: hubspotDealId,
              event_source: 'Online',
              cart_total: totalPrice ?? 0,
              currency_code: 'ZAR',
            });
            createHubspotPurchaseEvent(hubspotDealId);

            setHubspotDealId(null);
            setShopifyOrderId(null);
            setCartIdWithStorageAtom(null);

            const redirectUri = searchParams.get('redirectUri');

            // Check if the current page is the completed card order page
            const isIKCardOrderRedirect = pathname === '/completed-card-order';

            let redirect;
            if (isIKCardOrderRedirect) {
              // Check if user came from the IK Card page via redirectUri param
              const userIsFromIkCardPage = redirectUri === 'ik-card';
              // If from IK Card page, redirect back there, otherwise go to dashboard
              redirect = userIsFromIkCardPage
                ? `${getHomePageUrl()}/ik-card`
                : 'https://dashboard.ikhokha.com';
            } else {
              // For non-card orders, redirect to the auth signup flow
              redirect = formattedAuthUrl();
            }

            setTimeout(() => {
              window.location.href = redirect;
            }, 10000);
          }
        }
      };

      completedOrder();
    }
  }, [pathname, shopifyOrderId, hubspotDealId]);

  return (
    <>
      <div>{children}</div>
    </>
  );
}
